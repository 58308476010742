.notfound {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.notfound p {
  padding: 3em 4em;
  background: #3b3e46;
  border-radius: .3em;
  max-width: 220px;
  margin: 0 auto;
}