.banner {
  z-index: 900;
  position: fixed;
  background-color: rgba(56, 59, 67, .97);
  background-image: linear-gradient(-18deg, rgba(37, 38, 39, 0) 5%, rgba(82, 83, 88, .8) 140%);
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(0);
}

@supports (-webkit-backdrop-filter: blur(20px)) {
  .banner {
    background-color: rgba(37, 38, 39, .9);
    backdrop-filter: blur(20px) saturate(3);
    -webkit-backdrop-filter: blur(20px) saturate(3);
  }
}

.banner > section {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  max-width: 800px;
  margin: 1em auto .5em;
}

.banner > section > div {
  flex: 5 5 290px;
  margin: 0 1.5em;
  max-height: 100px;
  transition: all .2s ease-out;
  opacity: 1;
}

@media (max-width: 580px) {
  .hide > section > div:first-child {
    max-height: 0;
    opacity: 0;
  }
}

.banner > section > div:last-child {
  text-align: center;
  flex: 3 3 250px;
  margin: .5em 1em;
}

.termsnote {
  display: block;
  font-size: .875em;
  padding: .4em 0;
  color: rgba(255,255,255,.55);
}

.quotation {
  margin: .3em 0;
  font-style: italic;
  letter-spacing: .01em;
}

.author {
  text-align: right;
  margin-bottom: .5em;
}

.termsnote a {
  color: rgba(255,255,255,.55);
}