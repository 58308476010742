.header {
  background-color: #252627;
  background-image: linear-gradient(-18deg, #252627 20%, #525358 100%);
  text-align: center;
  margin: 0;
}

.header a {
  display: inline-flex;
  align-items: center;
  font-size: .875em;
  text-decoration: none;
  text-transform: uppercase;
  font-family: AltoPro, sans-serif;
  font-weight: normal;
  line-height: 1.2em;
  letter-spacing: 5px;
  color: rgba(255, 255, 255, .8);
  height: 42px;
  box-sizing: border-box;
  padding: .5em .8em;
  border-radius: .5em;
  margin: .9em 1em .63em;
}

.header a:hover {
  color: rgba(255, 255, 255, 1);
}

.header h2 {
  margin: 0 auto;
  padding: .7em 1em;
  height: 30px;
}

.header a + h2 {
  display: none;
}

@media (min-width: 1000px) {
  .header a {
    display: none;
  }

  .header a + h2 {
    display: block;
  }
}